import React from 'react';
import { Button, Group } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

// const HEADER_HEIGHT = 84;

const useStyles = createStyles((theme) => ({
  controlsWrapper: {
    position: 'fixed',
    width: '100%',
    height: '72px',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors[theme.primaryColor][8],
  },
}));

interface FormRunnerStepsControlsProps {
  onNext: () => void;
  onPrev: () => void;
  active: number;
  numberOfPages: number;
}

export const FormRunnerStepsControls: React.FC<FormRunnerStepsControlsProps> = ({
  onPrev,
  onNext,
  active,
  numberOfPages,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.controlsWrapper}>
      <Group justify={'center'}>
        <Button variant="default" onClick={onPrev} disabled={active === 0}>
          Föregående
        </Button>
        <Button onClick={onNext} disabled={active === numberOfPages}>
          Nästa
        </Button>
      </Group>
    </div>
  );
};
