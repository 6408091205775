import React, { useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { Link, useParams } from 'react-router-dom';
import { Button, Center, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';

import { loginRequest } from '../../AuthConfig';
import { FormRunner } from '../../components/formbuilder/FormRunner';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import { FileUpload } from '../../components/formbuilder/models/FileUpload';
import { UploadedFile } from '../../models/UploadedFile';
import { FileDelete } from '../../components/formbuilder/models/FileDelete';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { isMissing, isPresent } from 'utilitype';
import { ApplicationCompleted } from './ApplicationCompleted';
import { RequestCompletion } from '../../components/Completion/RequestCompletion';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import { FormElementMode } from '../../components/formbuilder/FormElementMode';
import { CompletionStatus } from '../../models/CompletionStatus';
import { ApplicationType } from '../../models/Application';
import { createStyles } from '@mantine/emotion';
import { IconMessages } from '@tabler/icons-react';

const useStyles = createStyles(() => ({
  applicationAndCompletions: {
    display: 'flex',
    flexDirection: 'row',
  },
  maxWidth: {
    width: '100%',
  },
}));

export const ApplicationFormView: React.FC = () => {
  const { applicationPeriodId } = useParams();
  const { applicationId } = useParams();
  const organisation = useOrganisationContext();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [application, setApplication] = useState<ApplicationDetails>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [showOngoingMessage, setShowOngoingMessage] = useState<boolean>(false);
  const [showCompletionComments, setShowCompletionComments] = useState(false);

  const { classes, theme } = useStyles();

  const createNewApplication = async () => {
    if (organisation && applicationPeriodId) {
      const createResponse = await api.createApplication(organisation.id, applicationPeriodId);
      setApplication(createResponse);
      setShowOngoingMessage(false);
    }
  };

  const fetchData = async () => {
    if (organisation) {
      if (applicationPeriodId) {
        const response = await api.getApplicationsByApplicationPeriodId(
          organisation.id,
          applicationPeriodId,
        );
        if (response && response.filter((x) => x.status === ApplicationStatus.Draft && x.type === ApplicationType.Application).length > 0) {
          setShowOngoingMessage(true);
        } else {
          await createNewApplication();
        }
      } else if (applicationId) {
        const response = await api.getApplication(organisation.id, applicationId);
        setApplication(response);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
  }, [organisation, isAuthenticated]);

  const complete = async (data: string) => {
    if (isPresent(organisation) && isPresent(application)) {
      const response = await api.updateApplication(
        organisation.id,
        application.id,
        JSON.parse(data),
        true,
      );
      if (response.ok) {
        //await fetchData();
        setIsCompleted(true);
      }
    }
  };

  const onPageChanged = async (data: string) => {
    if (isPresent(organisation) && isPresent(application)) {
      await api.updateApplication(organisation.id, application.id, JSON.parse(data), false);
    }
  };

  const onFileUpload = async (fileUpload: FileUpload): Promise<UploadedFile | undefined> => {
    if (organisation && application) {
      const response = await api.uploadFile(organisation.id, application.id, fileUpload);

      if (response.ok) {
        return response.json();
      }

      throw new Error('Unable to upload file');
    }
  };

  const onFileDelete = async (fileDelete: FileDelete) => {
    const { elementId, deleteComplete } = fileDelete;
    if (organisation && application) {
      await api.deleteUploadedFile(organisation.id, application.id, elementId);

      deleteComplete();
    } else {
      throw new Error('No organisation or application');
    }
  };

  const closeCompletionCommentsDrawer = () => {
    setShowCompletionComments(false);
  };

  const hasCompletionComments =
    application?.completionComments && application.completionComments.length > 0;

  const isCompletion =
    application?.status === ApplicationStatus.Submitted &&
    application.completionStatus === CompletionStatus.Requested;

  return (
    <GrantMeContainer>
      <AuthenticatedTemplate>
        {isMissing(organisation) ||
          (isMissing(application) && !showOngoingMessage && (
            <Group justify="center">
              <Loader />
            </Group>
          ))}
        <div className={classes.maxWidth}>
          {isCompleted && (
            <ApplicationCompleted
              runnerMode={isCompletion ? FormElementMode.Completion : FormElementMode.Runner}
              applicationType={application!.type}
            />
          )}

          {!isCompleted && showOngoingMessage && (
            <Center>
              <Stack>
                Du har redan en eller flera påbörjade ansökningar för detta stipendiet. Vill du
                fortsätta med en tidigare ansökan eller skapa en ny?
                <Group justify="center">
                  <Button component={Link} to={`/${organisation?.slug}/applications`}>
                    Visa påbörjade
                  </Button>
                  <Button variant="outline" onClick={() => createNewApplication()}>
                    Skapa ny
                  </Button>
                </Group>
              </Stack>
            </Center>
          )}

          {!isCompleted && application && (
            <Stack>
              <Group justify={'apart'}>
                <Title order={3}>{application.applicationPeriodTitle}</Title>
                {hasCompletionComments && (
                  <Group
                    // noWrap={true} !CHECK!
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowCompletionComments(true)}>
                    <Text fw={'bold'} color={theme.colors[theme.primaryColor][7]}>
                      Läs meddelanden
                    </Text>
                    <IconMessages size={32} color={theme.colors[theme.primaryColor][7]} />
                  </Group>
                )}
              </Group>

              <FormRunner
                runnerMode={isCompletion ? FormElementMode.Completion : FormElementMode.Runner}
                application={application}
                json={JSON.stringify(application.json)}
                onCompleted={complete}
                onPageChanged={onPageChanged}
                onFileUpload={onFileUpload}
                onFileDelete={onFileDelete}
              />
            </Stack>
          )}

          {!isCompleted && hasCompletionComments && (
            <RequestCompletion
              organisationId={organisation!.id}
              application={application}
              onCompletionRequested={fetchData}
              showCompletionComments={showCompletionComments}
              onCloseCompletionComments={closeCompletionCommentsDrawer}
            />
          )}
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Center>
          <Stack>
            <Text>
              För att kunna ansöka om ett stipendie måste du först logga in. Klicka på knappen nedan
              för att fortsätta.
            </Text>

            <Group justify="center">
              <Button
                onClick={() => {
                  instance.loginRedirect(loginRequest);
                }}>
                Logga in
              </Button>
            </Group>
          </Stack>
        </Center>
      </UnauthenticatedTemplate>
    </GrantMeContainer>
  );
};
