import { FC, useEffect, useState } from 'react';
import {
  Button,
  Group,
  Title,
  Stack,
  TextInput,
  Grid,
  Paper,
  Space,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { User } from '../../models/User';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { compact } from 'utilitype';
import { LiitIdentityNumberInput } from '../../components/LiitIdentityNumberInput/LiitIdentityNumberInput';
import { ErrorMessages } from '../../utils/errorMessages';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';
import { t } from 'ttag';
import { useApi } from '../../hooks/useApi';
import { INPUT_MAX_LENGTH } from '../../App';
import Validator from '../../utils/validators';
import { IconAt, IconPhone } from '@tabler/icons-react';

const validateIdentityNumber = (values: User) => {
  if (values.missingIdentityNumber) {
    return null;
  }

  const regExp = new RegExp('^(19|20)[0-9]{10}$', 'g');

  if (!values.identityNumber.match(regExp)) {
    return ErrorMessages.FAULTY_IDENTIFIER_FORMAT_PERSON;
  }

  const isValid = Validator.validateIdentityNumberSwedish(values.identityNumber);
  if (!isValid) {
    return ErrorMessages.FAULTY_IDENTIFIER_PERSON;
  }

  return null;
};

export const ProfileView: FC = () => {
  const api = useApi();
  const organisation = useOrganisationContext();
  const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

  const form = useForm<User>({
    initialValues: {
      identityNumber: '',
      missingIdentityNumber: false,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    },
    validate: (values) => ({
      identityNumber: validateIdentityNumber(values),
    }),
  });

  useEffect(() => {
    if (organisation) {
      const fetchData = async () => {
        setDisplayOverlay(true);
        try {
          const user = await api.getUser();
          form.setValues({ ...(compact(user) as User) });

        } catch {
          showFailNotification(
            t`Fel vid hämtning`,
            t`Något gick fel vid hämtning av användarinformationen`,
          );
        } finally {
          setDisplayOverlay(false);
        }
      };

      fetchData();
    }
  }, [organisation]);

  const save = async (values: User) => {
    if (form.validate().hasErrors) {
      return;
    }

    try {
      await api.updateUser(values);
      showSuccessNotification(t`Uppdatering sparad`, t`Användarinformationen har sparats`);
    } catch {
      showFailNotification(t`Fel vid sparning`, t`Något gick fel när informationen sparades`);
    }
  };

  if (!organisation) {
    return <div />;
  }

  return (
    <GrantMeContainer>
      <form onSubmit={form.onSubmit(async (values: User) => save(values))}>
        <Stack>
          <Title order={3}>{t`Min sida`}</Title>
          <Paper shadow="sm" p="md" withBorder>
            <LoadingOverlay visible={displayOverlay} />
            <Grid>
              <Grid.Col span={12}>
                <LiitIdentityNumberInput
                  label={t`Personnummer`}
                  missingIdentityNumberProps={form.getInputProps('missingIdentityNumber', {
                    type: 'checkbox',
                  })}
                  {...form.getInputProps('identityNumber')}
                />
              </Grid.Col>
              <Grid.Col span={{ sm: 6, xs: 12 }}>
                <TextInput
                  label={t`Förnamn`}
                  {...form.getInputProps('firstname')}
                  maxLength={INPUT_MAX_LENGTH}
                />
              </Grid.Col>
              <Grid.Col span={{ sm: 6, xs: 12 }}>
                <TextInput
                  label={t`Efternamn`}
                  {...form.getInputProps('lastname')}
                  maxLength={INPUT_MAX_LENGTH}
                />
              </Grid.Col>
              <Grid.Col span={{ sm: 6, xs: 12 }}>
                <TextInput
                  label={t`E-post`}
                  leftSection={<IconAt size={14} />}
                  {...form.getInputProps('email')}
                  maxLength={50}
                />
              </Grid.Col>
              <Grid.Col span={{ sm: 6, xs: 12 }}>
                <TextInput
                  label={t`Telefon`}
                  leftSection={<IconPhone size={14} />}
                  {...form.getInputProps('phone')}
                  maxLength={INPUT_MAX_LENGTH}
                />
              </Grid.Col>
            </Grid>
            <Space h={'md'} />
            <Group justify='end'>
              <Button type="submit">{t`Spara`}</Button>
            </Group>
          </Paper>
 
        </Stack>
      </form>
    </GrantMeContainer>
  );
};
