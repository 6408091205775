import { Grid, Select, Space, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { t } from 'ttag';
import { WayOfPayment } from '../../../models/WayOfPayment';
import { ApplicantAccountNumberField } from './ApplicantAccountNumberField';
import { ApplicantBgPgNumberField } from './ApplicantBgPgField';
import { ApplicantClearingNumberField } from './ApplicantClearingNumberField';

interface WayOfPaymentFormProps {
  form: UseFormReturnType<any>;
  space?: number;
  showViewer: boolean;
}

export const WayPaymentForm: React.FC<WayOfPaymentFormProps> = ({ form, showViewer, space }) => {
  const { wayOfPayment } = form.values;

  return (
    <>
    {space && <Space h={space} />}
    <Grid gutter={'xs'}>
      <Grid.Col span={12}>
        <Title order={4}>{t`Utbetalningsinformation`}</Title>
      </Grid.Col>
      {!showViewer && (
        <Grid.Col span={{ lg: 4 }} >
          <Select
            label="Typ"
            {...form.getInputProps('wayOfPayment')}
            data={[
              { value: WayOfPayment.BankAccount.toString(), label: 'Bankkonto' },
              { value: WayOfPayment.PlusGiro.toString(), label: 'PlusGiro' },
              { value: WayOfPayment.BankGiro.toString(), label: 'BankGiro' },
            ]}
            value={form.values.wayOfPayment.toString()}
            onChange={(event) => {
              form.setFieldValue('pgNumber', '');
              form.setFieldValue('bgNumber', '');
              form.setFieldValue('clearingNumber', '');
              form.setFieldValue('accountNumber', '');
              form.setFieldValue('wayOfPayment', Number(event));
            }}
          />
        </Grid.Col>
      )}
      {wayOfPayment === WayOfPayment.BankAccount && (
        <>
          <Grid.Col span={{ lg: showViewer ? 6 : 4 }}>
            <ApplicantClearingNumberField showViewer={showViewer} form={form} />
          </Grid.Col>
          <Grid.Col span={{ lg: showViewer ? 6 : 4 }}>
            <ApplicantAccountNumberField showViewer={showViewer} form={form} />
          </Grid.Col>
        </>
      )}
      {(wayOfPayment === WayOfPayment.PlusGiro || wayOfPayment === WayOfPayment.BankGiro) && (
        <Grid.Col span={{ lg: showViewer ? 12 : 8 }}>
          <ApplicantBgPgNumberField
            showViewer={showViewer}
            form={form}
            wayOfPayment={wayOfPayment}
          />
        </Grid.Col>
      )}
    </Grid>
    </>
  );
};
