import { TextInput, Text } from '@mantine/core';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import Banks from '../../../../utils/banks';
import { formatClearingNumber } from '../../../../utils/textFormatters';

type InputClearingNumberProps = {
  label: string;
  value: string;
  error: boolean;
  onChange: (value:string) => void;
};

export const InputClearingNumber: React.FC<InputClearingNumberProps> = ({ label, value, error, onChange }) => {
  const [bank, setBank] = useState<string>('');
  
  useEffect(() => {
    const number = Number(value);
    if (isNaN(number)) {
      setBank('');
      return;
    }

    if (number < 1000 || number > 99999) {
      setBank('');
      return;
    }

    setBank(Banks.getBankName(number));
  }, [value]);

  return (
    <TextInput
      label={label}
      maxLength={16}
      value={value}
      required
      error={error}
      // styles={{ rightSection: { width: 'fit-content', paddingRight: '8px' } }}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const newValue = event.target.value;
        event.target.value = formatClearingNumber(newValue);
      }}
      onChange={(event) => onChange(event.target.value)}
      rightSection={
        <Text size={'xs'} fw={700} c={'dimmed'}>
          {bank}
        </Text>
      }
    />
  );
};
