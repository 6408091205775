import {
  Button,
  Grid,
  Space,
  Stack,
  Stepper,
  Title,
  Text,
  Center,
  Paper,
  useMantineTheme,
  Checkbox,
  Group,
} from '@mantine/core';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { FormElement } from './models/FormElement';
import { FormModel } from './models/FormModel';
import { FormElementComponent } from './FormElementComponent';
import { FormElementMode } from './FormElementMode';
import { FormPage } from './models/FormPage';
import { FormViewer } from './FormViewer';
import { FormElementType } from './models/FormElementType';
import { FileUpload } from './models/FileUpload';
import { UploadedFile } from '../../models/UploadedFile';
import { FileDelete } from './models/FileDelete';
import { isPresent } from 'utilitype';
import { FormRunnerStepsControls } from './FormRunnerStepControls';
import { elementValidators } from './ElementValidators';
import { repeaterElementPath } from './FormElements/Repeater/repeaterElementPath';
import { validateRepeater } from './FormElements/Repeater/validateRepeater';
import { RepeaterRunnerModel } from './FormElements/Repeater/repeaterRunnerModel';
import { t } from 'ttag';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import LanguageHandler from '../../utils/languageHandler';
import { LanguageCode } from '../../models/LanguageCode';
import { ApplicationType } from '../../models/Application';
import { WayOfPayment } from '../../models/WayOfPayment';
import { ApplicantType } from '../../models/ApplicantType';

interface RunnerProps {
  runnerMode: FormElementMode;
  application: ApplicationDetails;
  json: string;
  onCompleted?: (json: string) => void;
  onPageChanged?: (json: string) => void;
  onFileUpload?: (fileUpload: FileUpload) => Promise<UploadedFile | undefined>;
  onFileDelete?: (file: FileDelete) => Promise<void>;
}

export interface ApplicantFormFields {
  firstName: string;
  lastName: string;
  organizationName: string;
  identityNumber: string;
  missingIdentityNumber: boolean;
  email: string;
  phone: string;
  clearingNumber: string;
  accountNumber: string;
  pgNumber: string;
  bgNumber: string;
  applicantType: ApplicantType;
  wayOfPayment: WayOfPayment;
  isByProxy: boolean;
  contactPersonFirstName: string;
  contactPersonLastName: string;
}

// TODO: Use https://mantine.dev/form/use-form/#useformreturntype instead?

export const FormRunner: FC<RunnerProps> = ({
  runnerMode,
  application,
  json,
  onCompleted,
  onPageChanged,
  onFileUpload,
  onFileDelete,
}) => {
  const [active, setActive] = useState(0);
  const [hasAcceptedAgreement, setHasAcceptedAgreement] = useState(false);

  const form = useForm({
    initialValues: {
      ...({ pages: [] } as FormModel),
    },
  });

  useEffect(() => {
    const result: FormModel = JSON.parse(json);
    form.setValues(result);
  }, [json]);

  const renderElement = (
    element: FormElement,
    pageIndex: number,
    index: number,
    questionNumber: number,
  ) => {
    const onChange = (value: unknown) => {
      if (element.type === FormElementType.FileGroup) {
        if (onFileUpload && value instanceof FileUpload) {
          onFileUpload(value)
            .then((uploadedFile) => {
              form.setFieldValue(`pages.${pageIndex}.elements.${index}.value`, uploadedFile);
            })
            .catch(() => {
              form.setFieldError(
                `pages.${pageIndex}.elements.${index}.value`,
                'Filen har ogiltigt format. Giltiga format är JPEG, PDF och PNG.',
              );
            });
        } else if (onFileDelete && value instanceof FileDelete) {
          onFileDelete(value)
            .then(() => {
              form.setFieldValue(`pages.${pageIndex}.elements.${index}.value`, null);
              form.clearFieldError(`pages.${pageIndex}.elements.${index}.value`);
            })
            .catch(() => {
              form.setFieldError(
                `pages.${pageIndex}.elements.${index}.value`,
                'Kunde inte ta bort filen',
              );
            });
        }
      } else {
        form.setFieldValue(`pages.${pageIndex}.elements.${index}.value`, value);
      }
    };

    return (
      <FormElementComponent
        number={questionNumber}
        element={element}
        mode={FormElementMode.Runner}
        error={form.errors[`pages.${pageIndex}.elements.${index}.value`]}
        onChange={onChange}
        repeaterRunnerInformation={
          element.type === FormElementType.Repeater
            ? ({
              form: form,
              pageIndex: pageIndex,
              elementIndex: index,
              onFileUpload: onFileUpload,
              onFileDelete: onFileDelete,
            } as RepeaterRunnerModel)
            : null
        }
      />
    );
  };

  const renderPage = (page: FormPage, pageIndex: number, startQuestionNumber: number) => (
    <React.Fragment key={`runner_page_${page.id}`}>
      {page.elements &&
        page.elements.map((element, index) => {
          return (
            <Grid.Col span={{ xs: 12, sm: element.size ?? 12 }} key={`element_${element.id}_${index}`}>
              {renderElement(element, pageIndex, index, startQuestionNumber + index)}
            </Grid.Col>
          );
        })}
    </React.Fragment>
  );

  const isPageValid = (pageIndex: number) => {
    let hasErrors = false;
    const formPageIndex = pageIndex;
    const page = form.values.pages[formPageIndex];

    for (const elementIndex in page.elements) {
      const element = page.elements[elementIndex];

      if (element.type === FormElementType.Repeater) {
        const { isValid, errorMessage, validationElements } = validateRepeater(element);
        if (!isValid) {
          form.setFieldError(`pages.${formPageIndex}.elements.${elementIndex}.value`, errorMessage);
          hasErrors = true;
        }

        for (const e in validationElements) {
          const validation = validationElements[e];
          if (!validation.validationResult.isValid) {
            const {
              groupIndex,
              elementIndex: eIndex,
              validationResult: { errorMessage: eMessage },
            } = validation;
            const path = repeaterElementPath(
              formPageIndex,
              Number(elementIndex),
              groupIndex,
              eIndex,
            );
            form.setFieldError(path, eMessage);
            hasErrors = true;
          }
        }
      } else {
        const validator = elementValidators[element.type];
        if (validator) {
          const { isValid, errorMessage } = validator(element);
          if (!isValid) {
            form.setFieldError(
              `pages.${formPageIndex}.elements.${elementIndex}.value`,
              errorMessage,
            );
            hasErrors = true;
          }
        }

        if (isPresent(form.errors[`pages.${formPageIndex}.elements.${elementIndex}.value`])) {
          hasErrors = true;
        }
      }
    }
    return !hasErrors;
  };

  const submit = () => {
    // const applicantFormHasErrors = applicantForm.validate().hasErrors;

    // if (applicantFormHasErrors) {
    //   return;
    // }
    const result = form.validate();
    let { hasErrors } = result;

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const pageIndex in form.values.pages) {
      const isValidPage = isPageValid(parseInt(pageIndex));

      if (isValidPage) {
        hasErrors = !isValidPage;
      }
    }

    if (!hasErrors && onCompleted) {
      //onCompleted(JSON.stringify(form.values, null, 4), applicantForm.values);
      onCompleted(JSON.stringify(form.values, null, 4));
    }
  };

  const numberOfPages = form.values.pages.length;

  const nextStep = () => {
    if (isPageValid(active)) {
      setActive((current) => (current < numberOfPages ? current + 1 : current));
      if (onPageChanged) {
        onPageChanged(JSON.stringify(form.values, null, 4));
      }
      window.scrollTo(0, 0);
    }
  };

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
    if (onPageChanged) {
      onPageChanged(JSON.stringify(form.values, null, 4));
    }
    window.scrollTo(0, 0);
  };

  const renderForm = () => {
    let startQuestionNumber = 0;

    if (form && form.values.pages) {
      return (
        <div>
            <Stepper
              iconSize={28}
              active={active}
              size={form.values.pages && form.values.pages.length > 3 ? 'xs' : 'sm'}>
              {form.values.pages.map((page, pageIndex) => {
                const pageLabel = LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv);

                const elements = (
                  <Stepper.Step key={`step_${page.id}`} label={pageLabel} description="">
                    <Paper shadow="sm" p="xl" withBorder>
                      {page.label && (
                        <>
                          <Title order={4}>{pageLabel}</Title>
                          <Space h={'lg'} />
                        </>
                      )}

                      <Grid gutter={'lg'}>{renderPage(page, pageIndex, startQuestionNumber)}</Grid>
                    </Paper>
                  </Stepper.Step>
                );
                startQuestionNumber += page.elements.length;
                return elements;
              })}
              <Stepper.Completed>
                <Stack pt="xl">
                  <Group justify="center">
                    <Title size="h4">Klart!</Title>
                  </Group>
                  <Group justify="center" pb="xl">
                    <Text size="sm">
                      {application.type === ApplicationType.Application
                        ? t`Granska dina uppgifter och klicka därefter på knappen nedan för att lämna in din ansökan.`
                        : t`Granska dina uppgifter och klicka därefter på knappen nedan för att lämna in din återrapport.`}
                    </Text>
                  </Group>
                  <FormViewer json={JSON.stringify(form.values, null, 4)} showPageLabels={false} />

                  <Paper shadow="sm" p="xl" withBorder>
                    <Stack>
                      <Text size="sm" fw="bold">
                        Behandling av personuppgifter
                      </Text>
                      <Text size="sm">
                        Härmed intygas på heder och samvete att lämnade uppgifter är med sanningen
                        överensstämmande. All behandling av personuppgifter sker i enlighet med
                        bestämmelser i Dataskyddsförordningen ((EU) 2016/679). Jag har tagit del av
                        bilagd information om behandling av personuppgifter. För det fall jag i
                        ansökan har lämnat s.k. särskilda kategorier av personuppgifter* samtycker
                        jag härmed till att Stiftelsen behandlar dessa uppgifter för ändamål som
                        angivits i den bilagda informationen. Jag intygar vidare att jag inhämtat
                        samtycke från samtliga myndiga personer vars uppgifter uppges i ansökan.
                      </Text>
                      <Text size="xs" fs={'italic'}>
                        * uppgifter om ras eller etniskt ursprung, politiska åsikter, religiös eller
                        filosofisk övertygelse, medlemskap i fackförening, genetiska uppgifter,
                        biometriska uppgifter för att entydigt identifiera mig eller uppgifter om
                        min hälsa, mitt sexualliv eller sexuella läggning
                      </Text>
                      <Checkbox
                        checked={hasAcceptedAgreement}
                        onChange={(event) => setHasAcceptedAgreement(event.currentTarget.checked)}
                        label="Jag intygar och godkänner"></Checkbox>
                    </Stack>
                  </Paper>
                </Stack>
                {onCompleted && (
                  <Center pb={16}>
                    <Button disabled={!hasAcceptedAgreement} mt="xl" onClick={() => submit()}>
                      {runnerMode === FormElementMode.Completion
                        ? t`Lämna in komplettering`
                        : application.type === ApplicationType.Application
                          ? t`Lämna in ansökan`
                          : t`Lämna in återrapport`}
                    </Button>
                  </Center>
                )}
              </Stepper.Completed>
            </Stepper>

            <FormRunnerStepsControls
              onNext={nextStep}
              onPrev={prevStep}
              active={active}
              numberOfPages={numberOfPages}
            />
        </div>
      );
    }

    return <></>;
  };

  return <Stack style={{ overflowX: 'hidden' }}>{renderForm()}</Stack>;
};
